<template>
  <div class="footer">
    <v-bottom-navigation class="nav-bottom" app grow color="primary">
      <v-btn
        class="btn-nav"
        v-for="[icon, text, link, badge] in mainLinks"
        :key="icon"
        :to="link !== 'more' ? { name: link } : undefined"
        @click="link === 'more' ? toggleMore() : null"
        :class="[
          $vuetify.breakpoint.xsOnly ? 'btn-nav-small' : '',
          isActive(link) ? 'v-btn--active' : $route.meta.section !== 'dashboard' ? 'not-active' : '',
        ]"
        :ripple="false"
      >
        <div
          class="pt-1 text-uppercase font-weight-bold"
          :class="timeClockDuration && $route.meta.section !== 'timeclock' && link === 'timeclock' ? 'clock' : ''"
        >
          {{ text }}
        </div>
        <v-icon>{{ icon }}</v-icon>
        <v-badge v-if="badge" dot color="red" offset-x="-8" offset-y="6"></v-badge>
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-sheet v-model="showMore" swipeable>
      <v-sheet class="text-center" height="450px">
        <v-list>
          <div class="more-sheet-header">
            <div class="handle-bar" @click="showMore = false"></div>
            <!-- <v-btn icon small class="close-button" @click="showMore = false">
              <v-icon>mdi-close-thick</v-icon>
            </v-btn> -->
          </div>

          <v-list-item-group>
            <v-list-item
              v-for="[icon, text, link, badge] in moreLinks"
              :key="link"
              :to="{ name: link }"
              @click="showMore = false"
              link
            >
              <v-list-item-icon :color="isMoreItemActive(link) ? 'primary' : ''">
                <v-icon :color="isMoreItemActive(link) ? 'primary' : ''">{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="more-nav-text text-left text-uppercase font-weight-bold">{{
                  text
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-badge v-if="badge" dot color="red"></v-badge>
                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheFooter",
  data: () => ({
    showMore: false,
  }),
  computed: {
    ...mapGetters("timeClock", ["timeClockDuration"]),
    ...mapGetters("notifications", ["notifications"]),
    ...mapGetters("ui", ["showPlanners"]),

    mainLinks() {
      return [
        ["mdi-view-dashboard-outline", this.$t("c_menu.t_home"), "dashboard", null],
        [
          "mdi-clock-time-three-outline",
          this.timeClockDuration && this.$route.meta.section !== "timeclock"
            ? this.timeClockDuration
            : this.$t("c_menu.t_timeclock"),
          "timeclock",
          null,
        ],
        ["mdi-camera-outline", "Log-It", "issues", null],
        ["mdi-form-select", this.$t("c_menu.t_forms"), "forms", null],
        ["mdi-menu", "More", "more", null],
      ].filter(Boolean);
    },
    moreLinks() {
      //todo: translate
      return [
        ["mdi-cube-send", this.$t("c_menu.t_messages"), "messages", this.notifications?.length > 0],
        this.showPlanners && ["mdi-calendar-check", "Planners", "planners", null],
        ["mdi-note-multiple-outline", "Documents", "documents", null],
        ["mdi-school-outline", "Training", "training", null],
        ["mdi-cog-outline", "Settings", "account", null],
      ].filter(Boolean);
    },
  },
  methods: {
    toggleMore() {
      this.showMore = !this.showMore;
    },
    isActive(link) {
      if (link === "more") {
        return this.moreLinks.some(([, , routeName]) => routeName === this.$route.name);
      }
      return this.$route.meta.section === link;
    },
    isMoreItemActive(link) {
      return this.$route.name === link || this.$route.meta?.section === link;
    },
  },
};
</script>

<style lang="scss">
.v-btn--active.no-active:not(:hover)::before {
  opacity: 0 !important;
  background-color: transparent !important;
}
.v-btn--active::before {
  background-color: transparent !important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 65px;
}
.theme--dark.v-bottom-navigation .v-btn.not-active.v-btn--active {
  color: rgba(255, 255, 255, 0.7) !important;
}
.theme--light.v-bottom-navigation .v-btn.not-active.v-btn--active {
  color: rgba(0, 0, 0, 0.6) !important;
}

.v-bottom-sheet {
  border-top: 3px solid #f44336;
  margin-bottom: calc(56px + env(safe-area-inset-bottom, 0px)) !important;
  z-index: 980;
  position: relative;
  .v-list-item-group {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 8px;
    &.theme--dark {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .v-list-item {
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &:last-child {
      border-bottom: none;
    }
    &.theme--dark {
      background: rgba(255, 255, 255, 0.02);
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
    .more-nav-text {
      font-family: "montserrat", sans-serif !important;
      font-size: 0.875rem !important;
      letter-spacing: 0.0892857143em;
    }
  }

  .more-sheet-header {
    position: relative;
    padding: 4px;
    .handle-bar {
      width: 36px;
      height: 4px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.2);
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .close-button {
      position: absolute;
      right: 16px;
      top: 1px;
    }
  }

  // Dark theme support
  .theme--dark {
    .handle-bar {
      background-color: rgba(255, 255, 255, 0.2);

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.footer {
  .nav-bottom {
    border-top: 1px solid #ccc;
    height: auto !important;
    z-index: 990;
    .clock {
      margin: 0 auto;
    }
    .btn-nav {
      padding-top: 7px !important;
      padding-bottom: constant(safe-area-inset-bottom) !important; /* move footer up on iPhone X - iOS 11.0 */
      padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important; /* move footer up on iPhone X - iOS 11.2 */
    }
    .btn-nav-small {
      font-size: 10px !important;
      font-family: "roboto", sans-serif !important;
      min-width: 50px !important;
    }
    .v-btn:before {
      background-color: transparent;
    }
    @media (max-width: 449px) {
      .btn-nav-small {
        font-size: 9px !important;
      }
    }
  }
}
</style>
